import {createApp} from 'vue'
import ElementPlus from "element-plus"
import "element-plus/dist/index.css"
import 'vant/lib/index.css';
import '@mdi/font/css/materialdesignicons.css'
// 注册指令
import vdown from '@/directive/vdown'
import App from './App.vue'
import {createPinia} from "pinia";
import {
    Button,
    Cell,
    CellGroup,
    ConfigProvider,
    Dialog,
    DropdownItem,
    DropdownMenu,
    Field,
    Form,
    Icon,
    Image,
    List,
    NavBar,
    Notify,
    Picker,
    Popup,
    Search,
    ShareSheet,
    Sticky,
    SwipeCell,
    Switch,
    Tabbar,
    TabbarItem,
    Tag,
    TextEllipsis,
    Uploader,
    Tabs,
    Tab,
    Checkbox,
    Popover,
    PullRefresh,
    CheckboxGroup,
    Loading
} from "vant";
import router from "@/router";
import 'v3-waterfall/dist/style.css'
import V3waterfall from "v3-waterfall";

//必须引入的核心
import Recorder from 'recorder-core'
//引入mp3格式支持文件；如果需要多个格式支持，把这些格式的编码引擎js文件放到后面统统引入进来即可
import 'recorder-core/src/engine/mp3'
import 'recorder-core/src/engine/mp3-engine'
//可选的插件支持项
import 'recorder-core/src/extensions/waveview'

const app = createApp(App)
app.use(createPinia())
app.use(ConfigProvider);
app.use(Tabbar);
app.use(TabbarItem);
app.use(NavBar);
app.use(Search);
app.use(Cell)
app.use(Image)
app.use(TextEllipsis)
app.use(Notify)
app.use(Picker)
app.use(Popup)
app.use(List);
app.use(Form);
app.use(Field);
app.use(CellGroup);
app.use(Button);
app.use(DropdownMenu);
app.use(Icon);
app.use(DropdownItem);
app.use(Sticky);
app.use(SwipeCell);
app.use(Dialog);
app.use(ShareSheet);
app.use(Switch);
app.use(Uploader);
app.use(Tag);
app.use(Tabs);
app.use(Tab);
app.use(Checkbox);
app.use(Popover);
app.use(PullRefresh);
app.use(CheckboxGroup);
app.use(Loading);
app.use(V3waterfall)
app.use(Recorder)
app.directive('down', vdown)
app.use(router).use(ElementPlus).mount('#app')


